<template>
  <div class="content-wrapper">
    <vue-json-editor
      v-model="resultInfo"
      :modes="modes"
      :mode="mode"
      :expanded-on-start="true"
      @json-change="onJsonChange"
      @has-error="onError"
      @json-save="onJsonSave"
    />
  </div>
</template>

<script>
import vueJsonEditor from 'vue-json-editor'

export default {
  components: {
    vueJsonEditor
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    resultInfo: {
      type: [Array, Object, String]
    },
    mode: {
      type: String,
      default: 'code'
    },
    modes: {
      type: Array,
      default: () => ['tree', 'code']
    },
    autoEvent: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      hasJsonFlag: true

    }
  },
  mounted () {
    const content = document.querySelector('.content-wrapper')
    content.children[0].style.height = '500px'
    this.autoEvent && this.$emit('onJsonChange', this.resultInfo, this.hasJsonFlag)
  },

  methods: {
    onJsonChange (value) {
      this.$emit('onJsonChange', value, this.hasJsonFlag)
    },
    onJsonSave () {
      this.hasJsonFlag = true
    },
    onError () {
      this.hasJsonFlag = false
    }
  }
}
</script>

<style>
.content-wrapper{
    width:100%;
    min-height: 500px;
}
.jsoneditor-vue{
  height: 100%;
}
</style>
